import React from 'react';
import { UserDto } from 'types/api/users';
import { NSWHealthLHD } from 'enums/user-info';
import {
  Badge,
  Skeleton,
  Box,
  Center,
  Loader,
  Checkbox,
  ActionIcon,
  Tooltip
} from '@mantine/core';
import {
  IconLock,
  IconCheckbox,
  IconSquare,
  IconDots
} from '@tabler/icons-react';
import { EventEnrolmentDto, EventInstanceDto } from 'types/api/event';
import dayjs from 'dayjs';

const ptc = (progress: number, total: number) => {
  if (progress === total) return 'green';
  if (progress === 0) return 'red';
  return 'yellow';
};

type Props = {
  count: number;
  enrolment: EventEnrolmentDto;
  user: UserDto;
  instances: EventInstanceDto[];
  isLoading: boolean;
  nBEFORE: number;
  nIMMEDIATELY_BEFORE: number;
  nAFTER: number;
  onChange: (instanceId: string, checked: boolean) => void;
  editing: Record<string, Record<string, boolean> | false>;
  showEmail: boolean;
  onShowDetails: (enrolmentId: string, userId: string) => void;
};

export const AttendanceRow: React.FC<Props> = ({
  count,
  enrolment,
  user,
  instances,
  isLoading,
  nBEFORE,
  nIMMEDIATELY_BEFORE,
  nAFTER,
  onChange,
  editing,
  showEmail,
  onShowDetails
}) => {
  const postReqActive =
    dayjs() >
    dayjs(instances[instances.length - 1].endsAt).subtract(1, 'hours');

  return (
    <tr>
      <td>{count}</td>
      <td>
        {!user && <Skeleton h="2rem" />}
        {user && !user.basic && user.email}
        {user && user.basic && `${user.basic.firstName} ${user.basic.lastName}`}
      </td>
      {showEmail && <td>{user.email}</td>}
      <td>{user?.nsw?.lhd ? NSWHealthLHD[user.nsw.lhd] : 'None'}</td>
      <td>
        <Badge radius="xs" color={ptc(enrolment.BEFORE, nBEFORE)}>
          {enrolment.BEFORE} / {nBEFORE}
        </Badge>
      </td>
      <td>
        <Badge
          radius="xs"
          color={ptc(enrolment.IMMEDIATELY_BEFORE, nIMMEDIATELY_BEFORE)}
        >
          {enrolment.IMMEDIATELY_BEFORE} / {nIMMEDIATELY_BEFORE}
        </Badge>
      </td>

      {instances.map(instance => {
        if (isLoading) {
          return (
            <td key={instance.id}>
              <Loader size="sm" />
            </td>
          );
        }
        const active = dayjs() > dayjs(instance.startsAt);
        if (active) {
          if (editing[instance.id]) {
            return (
              <td key={instance.id}>
                <Checkbox
                  checked={editing[instance.id][enrolment.id]}
                  onChange={({ currentTarget: { checked } }) =>
                    onChange(instance.id, checked)
                  }
                />
              </td>
            );
          }
          return (
            <td key={instance.id}>
              {enrolment.attendance[instance.id] ? (
                <IconCheckbox />
              ) : (
                <IconSquare />
              )}
            </td>
          );
        }
        return (
          <Box component="td" key={instance.id} bg="gray.2">
            <Center>
              <IconLock />
            </Center>
          </Box>
        );
      })}
      {postReqActive && (
        <td>
          <Badge radius="xs" color={ptc(enrolment.AFTER, nAFTER)}>
            {enrolment.AFTER} / {nAFTER}
          </Badge>
        </td>
      )}
      {!postReqActive && (
        <Box component="td" bg="gray.2">
          <Center>
            <IconLock />
          </Center>
        </Box>
      )}
      <td>
        <Tooltip label="More Details" withArrow>
          <ActionIcon onClick={() => onShowDetails(enrolment.id, user.id)}>
            <IconDots />
          </ActionIcon>
        </Tooltip>
      </td>
    </tr>
  );
};
