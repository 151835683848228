import {
  Alert,
  Anchor,
  Button,
  Grid,
  Input,
  LoadingOverlay,
  Select,
  Stack
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { IconAlertCircle, IconAt, IconSearch } from '@tabler/icons-react';
import { ColumnDef } from '@tanstack/react-table';
import { SortableHeader } from 'components/Table/SortableHeader';
import { DataTableVirtual } from 'components/Table/virtual';
import { format, isAfter } from 'date-fns';
import { AdminRoutes } from 'enums';
import { useCreateCourseEnrolment } from 'features/courses/api/create-course-enrolment';
import { useCourseEnrolments } from 'features/courses/api/get-course-enrolments';
import React from 'react';
import { Link } from 'react-router-dom';
import { courseEnrolmentService } from 'service/course-enrolment';
import { CourseEnrolment } from 'types/backend';

interface CourseEnrolmentsPageProps {
  id: string;
}

const columns: ColumnDef<CourseEnrolment>[] = [
  {
    header: 'Name',
    accessorFn: row =>
      row.first_name ? `${row.first_name} ${row.last_name}` : ''
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Completed',
    accessorFn: row => (row.completed_at ? 'Yes' : 'No'),
    enableGlobalFilter: false
  },
  {
    header: 'Expired',
    accessorFn: row =>
      isAfter(new Date(), new Date(row.expires_at)) ? 'Expired' : 'Active',
    enableGlobalFilter: false
  },
  {
    id: 'expires_at',
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          Expires At
        </SortableHeader>
      );
    },
    accessorKey: 'expires_at',
    cell: ({ row }) => format(new Date(row.original.expires_at), 'd MMM yyyy'),
    sortingFn: 'datetime',
    enableGlobalFilter: false
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <Anchor
          component={Link}
          to={`${AdminRoutes.courseEnrolmentView}/${row.original.enrolment_id}`}
        >
          Details
        </Anchor>
      );
    },
    enableGlobalFilter: false
  }
];

export const CourseEnrolmentsPage = ({ id }: CourseEnrolmentsPageProps) => {
  const enrolmentsQuery = useCourseEnrolments({ courseId: id });
  const createEnrolmentMutation = useCreateCourseEnrolment({
    mutationConfig: {
      onSuccess: data => {
        enrolmentsQuery.refetch();
      }
    }
  });

  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: values => ({
      email: isEmail(values.email) ? null : 'Please enter a valid email.'
    })
  });

  if (enrolmentsQuery.isLoading) return <LoadingOverlay visible />;

  return (
    <Stack>
      {enrolmentsQuery.isError && (
        <Alert icon={<IconAlertCircle />} title="Oops!" color="red">
          {enrolmentsQuery.error.message}
        </Alert>
      )}
      {createEnrolmentMutation.isError && (
        <Alert icon={<IconAlertCircle />} title="Oops!" color="red">
          {createEnrolmentMutation.error.message}
        </Alert>
      )}
      <form
        onSubmit={form.onSubmit(values => {
          createEnrolmentMutation.mutate({
            courseId: id,
            data: {
              userEmail: values.email
            }
          });
        })}
      >
        <Grid>
          <Grid.Col span={10}>
            <Input
              icon={<IconAt size="20" />}
              placeholder="User email"
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              fullWidth
              type="submit"
              loading={createEnrolmentMutation.isPending}
            >
              Enrol User
            </Button>
          </Grid.Col>
        </Grid>
      </form>
      <DataTableVirtual
        id="course-enrolments"
        columns={columns}
        data={enrolmentsQuery.data.data ?? []}
        rowSize={48}
        filterInputPlaceholder="Filter enrolments..."
        sortable
        filterable
      />
    </Stack>
  );
};
