import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { updateF2FType as updateF2FTypeRoutine } from 'store/face-to-face/routines';
import { Container, Box, Group, Title, Stack, Anchor } from '@mantine/core';
import { F2FTypeDto, UpdateF2FTypeDto } from 'types/api/face-to-face';

type Props = {
  f2f: F2FTypeDto;
  updateF2F: (arg0: UpdateF2FTypeDto) => void;
};

const F2FResourcesNonAdminComponent: React.FC<Props> = ({ f2f, updateF2F }) => {
  const resources = useMemo(
    () =>
      f2f.resources?.map(page => ({
        id: page.id,
        title: page.title,
        resources: page.resources.map(resource => ({ ...resource }))
      })),
    [f2f]
  );

  return (
    <Container size="md" my="xl">
      {resources?.map(page => (
        <Box key={page.id} my="xl">
          <Title mb="md" order={3}>
            {page.title}
          </Title>
          <Stack spacing="md">
            {page.resources.map(resource => (
              <Group key={resource.id}>
                <Anchor href={resource.link} target="_blank">
                  {resource.text}
                </Anchor>
              </Group>
            ))}
          </Stack>
        </Box>
      ))}
    </Container>
  );
};

const mapDispatchToProps = {
  updateF2F: updateF2FTypeRoutine
};

export const F2FResourcesNonAdmin = connect(
  null,
  mapDispatchToProps
)(F2FResourcesNonAdminComponent);
