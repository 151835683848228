import { Divider, Text, Flex, Group, Stack, Title } from '@mantine/core';
import { IconBook2, IconUser } from '@tabler/icons-react';
import { useUser } from 'features/user/api/get-user';
import React from 'react';
import { useEventEnrolmentCompletions } from '../api/get-event-enrolment-completions';
import { EventEnrolmentTask } from './enrolment-task';

interface EventEnrolmentCompletionDetailProps {
  enrolmentId: string;
  userId: string;
}

export const EventEnrolmentCompletionDetail = ({
  enrolmentId,
  userId
}: EventEnrolmentCompletionDetailProps) => {
  const eventEnrolmentCompletionsQuery = useEventEnrolmentCompletions({
    enrolmentId,
    queryConfig: {
      enabled: !!enrolmentId
    }
  });
  const userQuery = useUser({ id: userId });

  if (
    eventEnrolmentCompletionsQuery.isLoading ||
    !eventEnrolmentCompletionsQuery.data
  )
    return null;

  const user = userQuery.data?.data;

  return (
    <Stack spacing="2.5rem">
      {user && (
        <Stack>
          <Flex direction="column">
            <Group>
              <IconUser />
              <Text size="lg" weight="bold">
                Basic Information
              </Text>
            </Group>
            <Divider my="sm" />
          </Flex>
          <Group>
            <Text weight="bold">Full Name:</Text>
            <Text>
              {user.first_name ? `${user.first_name} ${user.last_name}` : ''}
            </Text>
          </Group>
          <Group>
            <Text weight="bold">Email:</Text>
            <Text>{user.email}</Text>
          </Group>
        </Stack>
      )}
      <Flex direction="column">
        <Group>
          <IconBook2 />
          <Text size="lg" weight="bold">
            Task Completion
          </Text>
        </Group>
        <Divider mt="sm" />
      </Flex>
      <Stack>
        <Title order={3}>Pre-Requisite Learning</Title>
        {eventEnrolmentCompletionsQuery.data.data.BEFORE.map(task => (
          <EventEnrolmentTask
            type={task.type}
            title={task.title}
            completed={task.completed}
          />
        ))}
      </Stack>
      <Stack>
        <Title order={3}>Pre-Event Surveys</Title>
        {eventEnrolmentCompletionsQuery.data.data.IMMEDIATELY_BEFORE.map(
          task => (
            <EventEnrolmentTask
              type={task.type}
              title={task.title}
              completed={task.completed}
            />
          )
        )}
      </Stack>
      <Stack>
        <Title order={3}>Post-Event Surveys</Title>
        {eventEnrolmentCompletionsQuery.data.data.AFTER.map(task => (
          <EventEnrolmentTask
            type={task.type}
            title={task.title}
            completed={task.completed}
          />
        ))}
      </Stack>
    </Stack>
  );
};
