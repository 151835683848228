import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { User } from 'types/backend';

export const getUser = ({
  id,
  expanded
}: {
  id: string;
  expanded?: boolean;
}): Promise<AppResponse<User>> => {
  return api.get(`/user-new/${id}${expanded ? `?expanded=${expanded}` : ''}`);
};

export const getUserQueryOptions = (id: string, expanded?: boolean) => {
  return queryOptions({
    queryKey: ['users', id, expanded],
    queryFn: () => getUser({ id, expanded })
  });
};

type UseUserOptions = {
  id: string;
  expanded?: boolean;
  queryConfig?: QueryConfig<typeof getUserQueryOptions>;
};

export const useUser = ({ id, expanded, queryConfig }: UseUserOptions) => {
  return useQuery({
    ...getUserQueryOptions(id, expanded),
    ...queryConfig
  });
};
