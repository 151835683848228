import { queryOptions, useQuery } from '@tanstack/react-query';
import { UserRoles } from 'enums';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { User } from 'types/backend';

export const getUsers = ({
  role
}: {
  role?: keyof typeof UserRoles;
} = {}): Promise<AppResponse<Array<User>>> => {
  return api.get(`/user-new${role ? `?role=${role}` : ''}`);
};

export const getUsersQueryOptions = (role?: keyof typeof UserRoles) => {
  return queryOptions({
    queryKey: ['users', role],
    queryFn: () => getUsers({ role })
  });
};

type UseUsersOptions = {
  role?: keyof typeof UserRoles;
  queryConfig?: QueryConfig<typeof getUsersQueryOptions>;
};

export const useUsers = ({ role, queryConfig }: UseUsersOptions = {}) => {
  return useQuery({
    ...getUsersQueryOptions(role),
    ...queryConfig
  });
};
