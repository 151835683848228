import { Alert, Anchor, Container, Tabs } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import React from 'react';
import { CourseReportExportPanel } from './course-export';
import { F2FReportExportPanel } from './f2f-export';

export const ReportPage = () => {
  return (
    <Container size="lg" my="lg">
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Warning"
        color="yellow"
        mb="xl"
      >
        <li>
          The data downloaded here includes only eLearning activity recorded on
          the IOI eLearning Hub. It does not include any CBTe, FBT, or EHAR
          eLearning data from the MHL platform.
        </li>
        <li>
          Your exports include user data from your LHD, as well as from NSW
          Health staff who did not specify their LHD (i.e., those with an
          “UNKNOWN LHD”).
        </li>
        <li>
          For any questions or further assistance, please contact&nbsp;
          <Anchor href="mailto:elearning@insideoutinstitute.org.au">
            elearning@insideoutinstitute.org.au
          </Anchor>
        </li>
      </Alert>
      <Tabs w="100%" defaultValue="course" color="dark" variant="pills">
        <Tabs.List position="center" grow>
          <Tabs.Tab value="course">eLearning</Tabs.Tab>
          <Tabs.Tab value="f2f">F2F</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="course">
          <CourseReportExportPanel />
        </Tabs.Panel>

        <Tabs.Panel value="f2f">
          <F2FReportExportPanel />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
