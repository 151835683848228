import { AppShell } from '@mantine/core';
import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import React, { ReactNode } from 'react';

interface AdminLayoutProps {
  children: ReactNode | Array<ReactNode>;
}

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  return (
    <AppShell layout="alt" navbar={<Sidebar />} header={<Header />}>
      {children}
    </AppShell>
  );
};
