import React from 'react';
import { Link } from 'react-router-dom';
import { EventDto } from 'types/api/event';
import { Routes } from 'enums';
import {
  EventEnrolmentStatus,
  EventEnrolmentStatusColors
} from 'enums/event/event.enrolment.status.enum';
import { Card, BackgroundImage, Group, Badge, Text } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import dayjs from 'dayjs';

type Props = {
  event: EventDto;
};

export const WithdrawnCard: React.FC<Props> = ({ event }) => {
  return (
    <Card
      withBorder
      shadow="sm"
      component={Link}
      to={`${Routes.ExpressionOfInterest}/${event.id}`}
    >
      <Card.Section>
        <BackgroundImage src={event.f2f.splash}>
          <Group position="right" mih="150px" align="flex-start" p="md">
            <Badge
              color={EventEnrolmentStatusColors.WITHDRAWN}
              leftSection={<IconUsers size="1rem" />}
            >
              {EventEnrolmentStatus.WITHDRAWN}
            </Badge>
          </Group>
        </BackgroundImage>
      </Card.Section>

      <Text fw="bold" mt="md">
        {event.name}
      </Text>

      <Text>
        {dayjs(event.instances[0].startsAt).format('DD MMM')}
        {event.instances.length > 0 &&
          dayjs(event.instances[event.instances.length - 1].startsAt).format(
            ' - DD MMM'
          )}
      </Text>
    </Card>
  );
};
