import {
  Anchor,
  Badge,
  Divider,
  Drawer,
  Flex,
  Group,
  Stack,
  Text
} from '@mantine/core';
import { IconCertificate, IconUser } from '@tabler/icons-react';
import { isAfter, isBefore } from 'date-fns';
import { NSWHealthLHD } from 'enums/user-info';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../api/get-user';
import { EditUserDetailsModal } from './EditUserDetailsModal';

interface UserDetailsDrawerProps {
  open: boolean;
  userId: string;
  onClose: () => void;
}

export const UserDetailsDrawer = ({
  open,
  userId,
  onClose
}: UserDetailsDrawerProps) => {
  const userQuery = useUser({ id: userId, expanded: true });

  const user = userQuery.data?.data;
  const today = new Date();

  const expiredCourses = user?.courses?.filter(course =>
    isBefore(course.expires_at, today)
  );
  const activeCourses = user?.courses?.filter(course =>
    isAfter(course.expires_at, today)
  );

  return (
    <Drawer
      opened={open}
      onClose={onClose}
      position="right"
      title="User Details"
    >
      {user && (
        <Stack my="lg">
          <Stack spacing="sm">
            <Flex direction="column">
              <Group position="apart">
                <Group>
                  <IconUser />
                  <Text size="lg" weight="bold">
                    Basic Information
                  </Text>
                </Group>
                <EditUserDetailsModal
                  userId={user.id}
                  nsw_lhds={[user.nsw_lhd]}
                />
              </Group>
              <Divider my="sm" />
            </Flex>
            <Group>
              <Text weight="bold">Full Name:</Text>
              <Text>
                {user.first_name ? `${user.first_name} ${user.last_name}` : ''}
              </Text>
            </Group>
            <Group>
              <Text weight="bold">Email:</Text>
              <Text>{user.email}</Text>
            </Group>
            {user.nsw_lhd && (
              <Group>
                <Text weight="bold">NSW LHD:</Text>
                <Badge>{NSWHealthLHD[user.nsw_lhd]}</Badge>
              </Group>
            )}
          </Stack>
          <Stack spacing="sm">
            <Flex direction="column">
              <Group>
                <IconCertificate />
                <Text size="lg" weight="bold">
                  Courses
                </Text>
              </Group>
              <Divider my="sm" />
            </Flex>
            {activeCourses?.length > 0 && (
              <Flex direction="column">
                <Text mb="md" c="dimmed" fz="xs" fw="bold" td="underline">
                  ACTIVE
                </Text>
                <Stack>
                  {activeCourses.map(course => (
                    <Anchor
                      component={Link}
                      to={`/administration/course-enrolment/${course.enrolment_id}`}
                    >
                      <Text key={course.id} truncate>
                        {course.title}
                      </Text>
                    </Anchor>
                  ))}
                </Stack>
              </Flex>
            )}
            {expiredCourses?.length > 0 && (
              <Flex direction="column">
                <Text mb="md" c="dimmed" fz="xs" fw="bold" td="underline">
                  EXPIRED
                </Text>
                <Stack spacing="sm">
                  {expiredCourses.map(course => (
                    <Anchor
                      component={Link}
                      to={`/administration/course-enrolment/${course.enrolment_id}`}
                    >
                      <Text key={course.id} truncate>
                        {course.title}
                      </Text>
                    </Anchor>
                  ))}
                </Stack>
              </Flex>
            )}
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};
