import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NSWHealthLHD } from 'enums/user-info';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { User } from 'types/backend';
import { getUserQueryOptions } from './get-user';

export const updateUser = ({
  id,
  nsw_lhds,
  date
}: {
  id: string;
  nsw_lhds?: NSWHealthLHD[];
  date: Date;
}): Promise<AppResponse<User>> => {
  return api.patch(`/user-new/${id}`, {
    nsw_lhds,
    update_date: date
  });
};

type UseUpdateUserOptions = {
  mutationConfig?: MutationConfig<typeof updateUser>;
};

export const useUpdateUser = ({
  mutationConfig
}: UseUpdateUserOptions = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      const [data, variables] = args;

      // queryClient.setQueryData(
      //   getUserQueryOptions(variables.id, true).queryKey,
      //   ({ data: user }) => {
      //     if (!data.data) return { data: user };
      //
      //     return data;
      //   }
      // );

      queryClient.invalidateQueries({
        queryKey: getUserQueryOptions(variables.id, true).queryKey
      });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: updateUser
  });
};
