import React, { useState } from 'react';
import { EventDto } from 'types/api/event';
import { Button, Text, Alert, Stack } from '@mantine/core';
import {
  IconAlertCircle,
  IconCheck,
  IconCircleCheck
} from '@tabler/icons-react';
import { EventEnrolmentStatus } from 'enums/event/event.enrolment.status.enum';
import dayjs from 'dayjs';
import { useWithdrawEventEnrolment } from 'features/event-enrolments/api/withdraw-event-enrolment';

type Props = {
  event: EventDto;
  isLoading: boolean;
  createEnrolment: (eventId: string) => void;
  error?: string;
};

export const LoggedInExpressionOfInterest: React.FC<Props> = ({
  event,
  isLoading,
  createEnrolment,
  error
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const withdrawApplicationMutation = useWithdrawEventEnrolment();

  return (
    <div>
      {event.enrolment && !submitted && (
        <Stack>
          <Text>
            You have already expressed your interest in this training.
          </Text>
          <Text>
            Your application&apos;s status is:{' '}
            <Text fw="bold" span>
              {EventEnrolmentStatus[event.enrolment.status]}
            </Text>
          </Text>
          <Text>
            You should expect to hear back about you application on{' '}
            {dayjs(event.preferencesDue).format('DD MMM YYYY')}.
          </Text>
          {event.enrolment?.status !== 'WITHDRAWN' && (
            <Stack spacing="sm" w="fit-content">
              <Text fw="bold">Can&apos;t attend this training?</Text>
              {withdrawApplicationMutation.isError && (
                <Alert
                  icon={<IconAlertCircle size="1rem" />}
                  title="Oops!"
                  color="red"
                >
                  Looks like there was a problem withdrawing your application.
                  Please try again.
                </Alert>
              )}
              {withdrawApplicationMutation.isSuccess && (
                <Alert
                  icon={<IconCircleCheck size="1rem" />}
                  title="Success!"
                  color="green"
                >
                  Your application for this training has been successfully
                  withdrawn.
                </Alert>
              )}
              <Button
                color="dark"
                onClick={() =>
                  withdrawApplicationMutation.mutate({
                    eventEnrolmentId: event.enrolment.id
                  })
                }
                disabled={withdrawApplicationMutation.isSuccess}
                loading={withdrawApplicationMutation.isPending}
              >
                Withdraw My Application
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      {event.enrolment && submitted && (
        <Alert color="green" icon={<IconCheck />}>
          Submitted, thanks! Your coordinator will review expressions of
          interest and notify you on{' '}
          {dayjs(event.preferencesDue).format('DD MMM YYYY')}.
        </Alert>
      )}

      {error && (
        <Alert title="Something went wrong" color="red">
          {error}
        </Alert>
      )}

      {!event.enrolment && (
        <Button
          color="cyan"
          uppercase
          loading={isLoading}
          onClick={() => {
            setSubmitted(true);
            createEnrolment(event.id);
          }}
        >
          Submit Expression of Interest
        </Button>
      )}
    </div>
  );
};
