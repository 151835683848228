import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import {
  Container,
  SimpleGrid,
  LoadingOverlay,
  Text,
  Alert,
  Tabs,
  Group,
  Button
} from '@mantine/core';
import { RootState, EventIndex, UserIndex } from 'types/state';
import { EventCard } from './EventCard';
import { NSWHealthLHD } from 'enums/user-info';
import { Routes, UserRoles } from 'enums';
import { useEvents } from 'features/events/api/get-events';
import { isBefore, sub } from 'date-fns';
import { EventExtended } from 'types/backend';
import { IconReportAnalytics } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

type Props = {
  coordinatorFor?: keyof typeof NSWHealthLHD;
  role: UserRoles;
};

const CoordinatorDashboardComponent: React.FC<Props> = ({
  coordinatorFor,
  role
}) => {
  const events = useEvents();

  const [currentEventList, historicalEventList]: [
    EventExtended[],
    EventExtended[]
  ] = events.data?.data?.reduce(
    (acc, curr) => {
      if (!curr.instances) return acc;

      const isHistorical = isBefore(
        curr.instances[0].starts_at,
        sub(new Date(), { weeks: 3 })
      );

      if (isHistorical) {
        return [acc[0], [...acc[1], curr]];
      }

      return [[...acc[0], curr], acc[1]];
    },
    [[], []]
  ) ?? [[], []];

  currentEventList.sort(
    (a, b) =>
      new Date(a.instances[0].starts_at).getTime() -
      new Date(b.instances[0].starts_at).getTime()
  );
  historicalEventList.sort(
    (a, b) =>
      new Date(a.instances[0].starts_at).getTime() -
      new Date(b.instances[0].starts_at).getTime()
  );

  return (
    <Container size="lg">
      <LoadingOverlay visible={events.isLoading} />

      {/* <Group position="right" my="xl">
        <Button component={Link} to={Routes.LHD} uppercase variant="filled" color="gray">
          Dashboard
        </Button>
        <Button component={Link} to={Routes.CalendarPage} uppercase variant="default" color="gray">
          Calendar
        </Button>
      </Group> */}

      {events.isError && (
        <Alert color="red" title="Something went wrong" my="lg">
          {events.error.message}
        </Alert>
      )}

      {role === UserRoles.LHD_COORDINATOR && !coordinatorFor && (
        <Alert color="red" title="No Associated LHD" my="lg">
          You are registered as a LHD Coordinator but do not have an associated
          LHD. You will not be able to access any training events.
        </Alert>
      )}

      <Tabs py="xl" defaultValue="current">
        <Tabs.List mb="xl" grow>
          <Tabs.Tab value="current" color="blue">
            Current
          </Tabs.Tab>

          <Tabs.Tab value="historical" color="cyan">
            Historical
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="current">
          <SimpleGrid cols={3}>
            {currentEventList.map(event => (
              <EventCard key={event.id} event={event} role={role} />
            ))}
          </SimpleGrid>

          {currentEventList.length === 0 && <Text>No upcoming events.</Text>}
        </Tabs.Panel>
        <Tabs.Panel value="historical">
          <SimpleGrid cols={3}>
            {historicalEventList.map(event => (
              <EventCard
                key={event.id}
                event={event}
                role={role}
                isHistorical
              />
            ))}
          </SimpleGrid>

          {historicalEventList.length === 0 && (
            <Text>No historical events.</Text>
          )}
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  coordinatorFor: state.user.coordinatorFor,
  role: state.user.role
});

const mapDispatchToProps = {};

export const CoordinatorDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoordinatorDashboardComponent);
