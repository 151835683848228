import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';

export const withdrawEventEnrolment = ({
  eventEnrolmentId
}: {
  eventEnrolmentId: string;
}): Promise<AppResponse<string>> => {
  return api.post(`/event-enrolment-new/${eventEnrolmentId}/withdraw`);
};

type UseWithdrawEventEnrolmentOptions = {
  mutationConfig?: MutationConfig<typeof withdrawEventEnrolment>;
};

export const useWithdrawEventEnrolment = ({
  mutationConfig
}: UseWithdrawEventEnrolmentOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: withdrawEventEnrolment
  });
};
