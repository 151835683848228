import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { z } from 'zod';

export const CreateUserBodySchema = z.object({
  email: z.string().email(),
  role: z.enum(['LEARNER', 'F2F_FACILITATOR', 'LHD_COORDINATOR', 'IOI_ADMIN'])
});

export type CreateUserBody = z.infer<typeof CreateUserBodySchema>;

export const createUser = ({
  data
}: {
  data: CreateUserBody;
}): Promise<AppResponse<string>> => {
  return api.post('/user-new', data);
};

type UseCreateUserOptions = {
  mutationConfig?: MutationConfig<typeof createUser>;
};

export const useCreateUser = ({
  mutationConfig
}: UseCreateUserOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: createUser
  });
};
