import { ActionIcon, Button, Modal, MultiSelect, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit } from '@tabler/icons-react';
import { NSWHealthLHD } from 'enums/user-info';
import React from 'react';
import { useUpdateUser } from '../api/update-user';
import { isDate } from 'date-fns';
import { DateInput } from '@mantine/dates';

interface EditUserDetailsModalProps {
  userId: string;
  nsw_lhds: NSWHealthLHD[];
}

const NSWHealthLHDData = Object.keys(NSWHealthLHD).map(lhd => ({
  value: lhd,
  label: NSWHealthLHD[lhd]
}));

export const EditUserDetailsModal = ({
  userId,
  nsw_lhds
}: EditUserDetailsModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      nsw_lhds,
      update_date: new Date()
    },
    validate: {
      nsw_lhds: value =>
        value.length === 0 ? 'Select at least one LHD' : null,
      update_date: value =>
        !isDate(value) ? 'Please select a valid date' : null
    }
  });

  const updateUserMutation = useUpdateUser({
    mutationConfig: {
      onSuccess: () => {
        close();
      }
    }
  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Update User Details"
        centered
      >
        <form
          onSubmit={form.onSubmit(values => {
            updateUserMutation.mutate({
              id: userId,
              nsw_lhds: values.nsw_lhds,
              date: values.update_date
            });
          })}
        >
          <Stack>
            <MultiSelect
              data={NSWHealthLHDData}
              label="NSW Health LHDs"
              placeholder="Select all that apply"
              withinPortal
              clearable
              {...form.getInputProps('nsw_lhds')}
            />
            <DateInput
              label="Select a date for the update"
              popoverProps={{ withinPortal: true }}
              {...form.getInputProps('update_date')}
            />
            <Button
              type="submit"
              color="dark"
              fullWidth
              loading={updateUserMutation.isPending}
            >
              Update Details
            </Button>
          </Stack>
        </form>
      </Modal>
      <ActionIcon onClick={open}>
        <IconEdit />
      </ActionIcon>
    </>
  );
};
