/* eslint-disable react/no-unstable-nested-components */
import { UserLayout } from 'components/UserLayout';
import PrivateRoute from 'containers/PrivateRoute';
import { LayoutType, UserRoles } from 'enums';
import { AdminRoutes } from 'enums/routing/admin-routes';
import { Routes, getBaseUrlByRole } from 'enums/routing/routes';
import { getAllAdminRoles, getAllRoles } from 'enums/user-roles';
import { Users } from 'pages/Administration/Users';
import { ActivateAccount } from 'pages/Common/ActivateAccount';
import { AwaitingActivation } from 'pages/Common/AwaitingActivation';
import { Login } from 'pages/Common/Login';
import { PrivacyPolicy } from 'pages/Common/PrivacyPolicy';
import { Registration } from 'pages/Common/Registration';
import { TermsAndConditions } from 'pages/Common/TermsAndConditions';
import { CoordinatorDashboard } from 'pages/LHDCoordinator';
import { AttendanceSheet } from 'pages/LHDCoordinator/AttendanceSheet';
import { ExpressionOfInterestViewer } from 'pages/LHDCoordinator/ExpressionOfInterestViewer';
import { CoordinatorEvent } from 'pages/LHDCoordinator/View';
import { Dashboard } from 'pages/Learner/Dashboard';
import { TrainingsDetail } from 'pages/Learner/Trainings/Detail';
import React from 'react';
import { connect } from 'react-redux';
import {
  Navigate,
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router
} from 'react-router-dom';
import { RootState, UserState } from 'types/state';
// import { HelpAndSupport } from 'pages/Common/HelpAndSupport';
// import { ContactUs } from 'pages/Common/ContactUs';
import { CourseList } from 'pages/Administration/Course';
import { CourseNew } from 'pages/Administration/Course/New';
import { CourseView } from 'pages/Administration/Course/View';
import { AdminCourseEnrolmentView } from 'pages/Administration/CourseEnrolment';
import { DirectSurvey } from 'pages/Administration/Direct';
import { F2FEventList } from 'pages/Administration/Events';
import { F2FEventNew } from 'pages/Administration/Events/New';
import { F2FEventView } from 'pages/Administration/Events/View';
import { F2FTypeList } from 'pages/Administration/FaceToFace';
import { F2FTypeNew } from 'pages/Administration/FaceToFace/New';
import { F2FTypeView } from 'pages/Administration/FaceToFace/View';
import { FileList } from 'pages/Administration/File';
import { ScormList } from 'pages/Administration/Scorm';
import { ScormNew } from 'pages/Administration/Scorm/New';
import { ScormView } from 'pages/Administration/Scorm/View';
import { Supervision } from 'pages/Administration/Supervision';
import { SurveyList } from 'pages/Administration/Survey';
import { SurveyNew } from 'pages/Administration/Survey/New';
import { SurveyView } from 'pages/Administration/Survey/View';
import { Landing } from 'pages/Common/Landing';
import { ResetPassword } from 'pages/Common/ResetPassword';
import { ResetPasswordRequest } from 'pages/Common/ResetPasswordRequest';
import { CalendarPage } from 'pages/LHDCoordinator/CalendarPage';
import { AccountSettings } from 'pages/Learner/AccountSettings';
import { Certificates } from 'pages/Learner/Certificates';
import { CourseResources } from 'pages/Learner/CourseEnrolment/Resources';
import { CourseEnrolmentView } from 'pages/Learner/CourseEnrolment/View';
import { CourseStore } from 'pages/Learner/CourseStore';
import { CourseStoreView } from 'pages/Learner/CourseStore/View';
import { ExpressionOfInterest } from 'pages/Learner/ExpressionOfInterest';
import { Onboarding } from 'pages/Learner/Onboarding';
import { F2FResources } from 'pages/Learner/Trainings/Resources';
import { DataExportPage } from 'pages/data-export';
import { ReportPage } from 'pages/report';
import { CouponPage } from 'pages/coupon';
import { CouponRedemptionPage } from 'pages/coupon/redemption';
import { AdminLayout } from 'components/layout/admin';

const { USER_LAYOUT, ADMIN_LAYOUT } = LayoutType;

const wrapIntoPrivateRoute = (
  user: UserState,
  child: any,
  layoutType: LayoutType,
  rolesAllowedToVisit: UserRoles[] = [],
  canBeInactive = false
) => {
  const Layout = layoutType === USER_LAYOUT ? UserLayout : AdminLayout;

  return (
    <PrivateRoute
      user={user}
      rolesAllowedToVisit={rolesAllowedToVisit}
      canBeInactive={canBeInactive}
    >
      <Layout>{child}</Layout>
    </PrivateRoute>
  );
};

const wrapIntoUserPrivateRoute = (
  user: UserState,
  child: any,
  rolesAllowedToVisit: UserRoles[] = getAllRoles(),
  canBeInactive = false
) => {
  return wrapIntoPrivateRoute(
    user,
    child,
    USER_LAYOUT,
    rolesAllowedToVisit,
    canBeInactive
  );
};

const wrapIntoAdminPrivateRoute = (
  user: UserState,
  child: any,
  rolesAllowedToVisit: UserRoles[] = getAllAdminRoles(),
  canBeInactive = false
) => {
  return wrapIntoPrivateRoute(
    user,
    child,
    ADMIN_LAYOUT,
    rolesAllowedToVisit,
    canBeInactive
  );
};

type Props = {
  user: UserState;
};

const AppRoutes: React.FC<Props> = ({ user }) => (
  <Router>
    <ReactRoutes>
      <Route
        path={Routes.BaseUrl}
        element={<Navigate to={getBaseUrlByRole(user?.role)} />}
      />
      <Route path={Routes.Landing} element={<Landing />} />
      {/*
      <Route path={Routes.Help}
        element={<UserLayout><HelpAndSupport /></UserLayout>}
      />
      <Route path={Routes.ContactUs}
        element={<UserLayout><ContactUs /></UserLayout>}
      />
      */}
      <Route
        path={Routes.TermsAndConditions}
        element={
          <UserLayout>
            <TermsAndConditions />
          </UserLayout>
        }
      />
      <Route
        path={Routes.PrivacyPolicy}
        element={
          <UserLayout>
            <PrivacyPolicy />
          </UserLayout>
        }
      />
      <Route
        path={Routes.Login}
        element={
          <UserLayout>
            <Login />
          </UserLayout>
        }
      />
      <Route
        path={Routes.Registration}
        element={
          <UserLayout>
            <Registration />
          </UserLayout>
        }
      />
      <Route
        path={Routes.AwaitingActivation}
        element={
          <UserLayout>
            <AwaitingActivation />
          </UserLayout>
        }
      />
      <Route
        path={Routes.AccountActivation}
        element={
          <UserLayout>
            <ActivateAccount />
          </UserLayout>
        }
      />
      <Route
        path={Routes.ResetPasswordRequest}
        element={
          <UserLayout>
            <ResetPasswordRequest />
          </UserLayout>
        }
      />
      <Route
        path={Routes.ResetPassword}
        element={
          <UserLayout>
            <ResetPassword />
          </UserLayout>
        }
      />
      <Route
        path={`${Routes.DirectSurvey}/:type/:id`}
        element={
          <UserLayout>
            <DirectSurvey />
          </UserLayout>
        }
      />
      <Route path={Routes.CourseStore} element={<CourseStore />} />
      <Route path={`${Routes.CourseStore}/:id`} element={<CourseStoreView />} />
      <Route
        path={`${Routes.ExpressionOfInterest}/:id`}
        element={
          <Onboarding>
            <UserLayout>
              <ExpressionOfInterest />
            </UserLayout>
          </Onboarding>
        }
      />

      {/* Private User Routes */}
      <Route
        path={Routes.Dashboard}
        element={wrapIntoUserPrivateRoute(user, <Dashboard />, [
          UserRoles.LEARNER
        ])}
      />
      <Route
        path={`${Routes.courseEnrolment}/:id`}
        element={wrapIntoPrivateRoute(
          user,
          <CourseEnrolmentView />,
          USER_LAYOUT,
          getAllRoles()
        )}
      />
      <Route
        path={`${Routes.CourseResources}/:id`}
        element={wrapIntoPrivateRoute(
          user,
          <CourseResources />,
          USER_LAYOUT,
          getAllRoles()
        )}
      />
      <Route
        path={`${Routes.Trainings}/:id`}
        element={wrapIntoPrivateRoute(
          user,
          <TrainingsDetail />,
          USER_LAYOUT,
          getAllRoles()
        )}
      />
      <Route
        path={`${Routes.F2FResources}/:id`}
        element={wrapIntoPrivateRoute(
          user,
          <F2FResources />,
          USER_LAYOUT,
          getAllRoles()
        )}
      />
      <Route
        path={Routes.Certificates}
        element={wrapIntoPrivateRoute(
          user,
          <Certificates />,
          USER_LAYOUT,
          getAllRoles()
        )}
      />
      <Route
        path={Routes.AccountSettings}
        element={wrapIntoUserPrivateRoute(
          user,
          <AccountSettings />,
          getAllRoles(),
          true
        )}
      />
      <Route
        path={Routes.Events}
        element={wrapIntoPrivateRoute(user, <div>Events</div>, USER_LAYOUT, [
          UserRoles.LEARNER
        ])}
      />
      <Route
        path={`${Routes.Events}/:id`}
        element={wrapIntoPrivateRoute(
          user,
          <div>Event Detail</div>,
          USER_LAYOUT,
          [UserRoles.LEARNER]
        )}
      />
      {/* Private Coordinator Routes */}
      <Route
        path={Routes.LHD}
        element={wrapIntoAdminPrivateRoute(user, <CoordinatorDashboard />, [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path={Routes.CalendarPage}
        element={wrapIntoPrivateRoute(
          user,
          <CalendarPage />,
          USER_LAYOUT,
          getAllAdminRoles()
        )}
      />
      <Route
        path={`${Routes.LHD}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <CoordinatorEvent />, [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path={Routes.Reports}
        element={wrapIntoPrivateRoute(
          user,
          <ReportPage />,
          USER_LAYOUT,
          getAllAdminRoles()
        )}
      />
      <Route
        path={`${Routes.LHD}/:id/:tabValue`}
        element={wrapIntoAdminPrivateRoute(user, <CoordinatorEvent />, [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path={`${Routes.ExpressionOfInterestViewer}/:id`}
        element={wrapIntoPrivateRoute(
          user,
          <ExpressionOfInterestViewer />,
          USER_LAYOUT,
          getAllAdminRoles()
        )}
      />
      <Route
        path={`${Routes.AttendanceSheet}/:id`}
        element={<AttendanceSheet />}
      />
      {/* Private Admin Routes */}
      <Route
        path={Routes.Administration}
        element={wrapIntoAdminPrivateRoute(user, <div>Administration</div>)}
      />
      <Route
        path={AdminRoutes.users}
        element={wrapIntoAdminPrivateRoute(user, <Users />)}
      />
      <Route
        path={AdminRoutes.faceToFaceList}
        element={wrapIntoAdminPrivateRoute(user, <F2FTypeList />, [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path={AdminRoutes.faceToFaceNew}
        element={wrapIntoAdminPrivateRoute(user, <F2FTypeNew />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.faceToFaceView}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <F2FTypeView />, [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path={AdminRoutes.courseList}
        element={wrapIntoAdminPrivateRoute(user, <CourseList />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.courseNew}
        element={wrapIntoAdminPrivateRoute(user, <CourseNew />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.courseView}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <CourseView />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.courseEnrolmentView}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <AdminCourseEnrolmentView />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.coupon}
        element={wrapIntoAdminPrivateRoute(user, <CouponPage />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.coupon}/:id/redemption`}
        element={wrapIntoAdminPrivateRoute(user, <CouponRedemptionPage />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.scormList}
        element={wrapIntoAdminPrivateRoute(user, <ScormList />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.scormNew}
        element={wrapIntoAdminPrivateRoute(user, <ScormNew />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.scormView}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <ScormView />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.surveyList}
        element={wrapIntoAdminPrivateRoute(user, <SurveyList />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.surveyNew}
        element={wrapIntoAdminPrivateRoute(user, <SurveyNew />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.surveyView}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <SurveyView />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.eventList}
        element={wrapIntoAdminPrivateRoute(user, <F2FEventList />)}
      />
      <Route
        path={AdminRoutes.eventNew}
        element={wrapIntoAdminPrivateRoute(user, <F2FEventNew />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={`${AdminRoutes.eventView}/:id`}
        element={wrapIntoAdminPrivateRoute(user, <F2FEventView />)}
      />
      <Route
        path={AdminRoutes.fileList}
        element={wrapIntoAdminPrivateRoute(user, <FileList />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.supervisions}
        element={wrapIntoAdminPrivateRoute(user, <Supervision />, [
          UserRoles.IOI_ADMIN
        ])}
      />
      <Route
        path={AdminRoutes.reports}
        element={wrapIntoAdminPrivateRoute(user, <ReportPage />, [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path={AdminRoutes.dataExport}
        element={wrapIntoAdminPrivateRoute(user, <DataExportPage />, [
          UserRoles.IOI_ADMIN,
          UserRoles.F2F_FACILITATOR
        ])}
      />
      <Route
        path="*"
        element={<Navigate to={getBaseUrlByRole(user?.role)} />}
      />
    </ReactRoutes>
  </Router>
);

const mapStateToProps = (rootState: RootState) => ({
  user: rootState.user
});

export default connect(mapStateToProps, null)(AppRoutes);
