import {
  Button,
  Drawer,
  LoadingOverlay,
  Select,
  Stack,
  Table,
  Text,
  Title
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { format } from 'date-fns';
import { UserRoles } from 'enums';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { fetchEnrolmentsForEvent as fetchEnrolmentsForEventRoutine } from 'store/event-enrolment/routines';
import { linkUserToSurveyAnswer as linkUserToSurveyAnswerRoutine } from 'store/survey-answer/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { EventDto } from 'types/api/event';
import { SurveyAnswerDto } from 'types/api/survey';
import {
  EventEnrolmentIndex,
  EventEnrolmentListIndex,
  RootState,
  UserIndex
} from 'types/state';
import { SurveyAnswerIndex } from 'types/state/survey-answer';

type Props = {
  events: EventDto[];
  f2fTaskId: string;
  learners: UserIndex;
  eventEnrolments: EventEnrolmentIndex;
  eventEnrolmentLists: EventEnrolmentListIndex;
  surveyAnswers: SurveyAnswerIndex;
  surveyIsLoading: boolean;
  surveyLoadingError?: string;
  fetchEnrolmentsForEvent: (arg0: string) => void;
  linkUserToSurveyAnswer: (arg0: { id: string; userId: string }) => void;
};

const formatEventDate = (event: EventDto) => {
  const { instances } = event;

  const startDate = new Date(instances[0].startsAt);
  const endDate = new Date(instances[instances.length - 1].startsAt);

  if (instances.length === 1) {
    return {
      value: event.id,
      label: startDate.toLocaleDateString('en-au', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
      })
    };
  }

  return {
    value: event.id,
    label: `${startDate.toLocaleDateString('en-au', {
      day: 'numeric',
      month: 'short',
      year: '2-digit'
    })} - ${endDate.toLocaleDateString('en-au', {
      day: 'numeric',
      month: 'short',
      year: '2-digit'
    })}`
  };
};

const SurveyAnswersTableComponent: React.FC<Props> = ({
  events,
  f2fTaskId,
  learners,
  eventEnrolments,
  eventEnrolmentLists,
  surveyAnswers,
  surveyIsLoading,
  surveyLoadingError,
  fetchEnrolmentsForEvent,
  linkUserToSurveyAnswer
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<SurveyAnswerDto>();
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [selectedUserId, setSelectedUserId] = useState<string>();

  const [opened, { open, close }] = useDisclosure(false);

  const eventsData = useMemo(
    () => events?.map(event => formatEventDate(event)),
    [events]
  );
  const enrolmentsData = useMemo(() => {
    if (!selectedEventId) return [];

    const eventEnrolmentList = eventEnrolmentLists[selectedEventId] ?? [];
    const enrolments = eventEnrolmentList.map(id => eventEnrolments[id]);

    return enrolments.map(enrolment => ({
      label: learners[enrolment.userId]?.email ?? 'No Email',
      value: enrolment.userId
    }));
  }, [selectedEventId, eventEnrolmentLists, eventEnrolments, learners]);
  const answers = useMemo(
    () => surveyAnswers[f2fTaskId],
    [surveyAnswers, f2fTaskId]
  );

  const handleLinkBtnClick = useCallback(
    (answer: SurveyAnswerDto) => {
      setSelectedAnswer(answer);
      open();
    },
    [open]
  );

  const handleEventSelect = useCallback(
    (eventId: string) => {
      if (!eventEnrolmentLists[eventId]) {
        fetchEnrolmentsForEvent(eventId);
      }

      setSelectedEventId(eventId);
    },
    [eventEnrolmentLists, fetchEnrolmentsForEvent]
  );

  const handleSaveBtnClick = useCallback(() => {
    if (!selectedAnswer || !selectedUserId) return;

    linkUserToSurveyAnswer({ id: selectedAnswer.id, userId: selectedUserId });

    notifications.show({
      id: 'link-user-notification',
      message: 'Linking to user',
      color: 'green',
      style: { fontWeight: '500' },
      loading: true
    });
  }, [selectedAnswer, selectedUserId]);

  useEffect(() => {
    if (!surveyIsLoading) {
      notifications.update({
        id: 'link-user-notification',
        message: surveyLoadingError
          ? 'An error occured trying to link answer to user'
          : 'Successfully linked answer to user',
        color: surveyLoadingError ? 'red' : 'green',
        icon: surveyLoadingError ? (
          <IconX size="1rem" />
        ) : (
          <IconCheck size="1rem" />
        ),
        style: { fontWeight: '500' },
        autoClose: 5000
      });
    }
  }, [surveyIsLoading, surveyLoadingError]);

  if (!answers) return <LoadingOverlay visible />;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Date</th>
            <th style={{ textAlign: 'right' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {answers.map(answer => (
            <tr key={answer.id}>
              <td>{answer.email}</td>
              <td>{format(answer.createdAt, 'PP')}</td>
              <td style={{ textAlign: 'right' }}>
                <Button onClick={() => handleLinkBtnClick(answer)}>Link</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        overlayProps={{ opacity: 0.5, blur: 4 }}
        keepMounted={false}
      >
        <Stack>
          <Title order={2}>Link user</Title>
          <Stack>
            <Stack spacing="xs">
              <Title order={4}>Email</Title>
              <Text>{selectedAnswer?.email}</Text>
            </Stack>
            <Stack spacing="xs">
              <Title order={4}>Event</Title>
              <Select
                placeholder="Pick one"
                maxDropdownHeight={360}
                data={eventsData}
                onChange={handleEventSelect}
              />
            </Stack>
            <Stack spacing="xs">
              <Title order={4}>User</Title>
              <Select
                placeholder="Pick one"
                maxDropdownHeight={360}
                data={enrolmentsData}
                onChange={id => setSelectedUserId(id)}
              />
            </Stack>
            <Button onClick={handleSaveBtnClick}>Save</Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  learners: state.users.LEARNER,
  eventEnrolments: state.eventEnrolment.enrolments,
  eventEnrolmentLists: state.eventEnrolment.enrolmentLists,
  surveyAnswers: state.surveyAnswer.surveyAnswers,
  surveyIsLoading: state.surveyAnswer.isLoading,
  surveyLoadingError: state.surveyAnswer.error
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine,
  fetchEnrolmentsForEvent: fetchEnrolmentsForEventRoutine,
  linkUserToSurveyAnswer: linkUserToSurveyAnswerRoutine
};

export const SurveyAnswersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyAnswersTableComponent);
