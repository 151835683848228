import { Badge } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { SortableHeader } from 'components/Table/SortableHeader';
import { DataTableVirtual } from 'components/Table/virtual';
import { NSWHealthLHD } from 'enums/user-info';
import React from 'react';
import { User } from 'types/backend';
import { useUsers } from '../api/get-users';

const columns: ColumnDef<User>[] = [
  {
    id: 'name',
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          Name
        </SortableHeader>
      );
    },
    accessorFn: row =>
      row.first_name ? `${row.first_name} ${row.last_name}` : '',
    enableSorting: true
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Local Health District',
    cell: ({ row }) => (
      <Badge color="orange">
        {row.original.coordinator_for &&
          NSWHealthLHD[row.original.coordinator_for]}
      </Badge>
    )
  },
  {
    header: 'Active',
    cell: ({ row }) => {
      if (row.original.is_active) {
        return <Badge>Active</Badge>;
      }

      return <Badge color="red">Inactive</Badge>;
    }
  }
];

export const LHDCoordinatorList = () => {
  const usersQuery = useUsers({ role: 'LHD_COORDINATOR' });

  if (usersQuery.isLoading) {
    return null;
  }

  const users = usersQuery.data?.data;

  if (!users) return null;

  return (
    <DataTableVirtual
      id="lhd_coordinator"
      columns={columns}
      data={users}
      rowSize={36}
      filterInputPlaceholder="Filter users..."
      sortable
      filterable
    />
  );
};
