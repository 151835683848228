import {
  Anchor,
  createStyles,
  rem,
  Navbar,
  Title,
  Group,
  BackgroundImage,
  UnstyledButton,
  Tooltip
} from '@mantine/core';
import {
  IconBook2,
  IconCalendarEvent,
  IconChalkboard,
  IconDatabaseExport,
  IconPackage,
  IconPhoto,
  IconReportAnalytics,
  IconTicket,
  IconUser,
  IconZoomIn,
  IconZoomQuestion,
  IconLayoutSidebarLeftCollapseFilled,
  IconLayoutSidebarLeftExpandFilled
} from '@tabler/icons-react';
import { AdminRoutes, Routes, UserRoles } from 'enums';
import { getAllAdminRoles } from 'enums/user-roles';
import { useCurrentUser } from 'features/user/api/get-current-user';
import React, { ReactNode, useMemo, useState } from 'react';
import logo from 'assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';

type SidebarItem = {
  title: string;
  icon: ReactNode;
  link: AdminRoutes | Routes;
  roles: Array<keyof typeof UserRoles>;
};

type SidebarGroup = {
  header: string;
  items: Array<SidebarItem>;
};

const sidebarDef: Array<SidebarGroup> = [
  {
    header: 'User managment',
    items: [
      {
        title: 'Users',
        icon: <IconUser />,
        link: AdminRoutes.users,
        roles: [UserRoles.IOI_ADMIN]
      }
    ]
  },
  {
    header: 'Trainings',
    items: [
      {
        title: 'Face to Face',
        icon: <IconChalkboard />,
        link: AdminRoutes.faceToFaceList,
        roles: getAllAdminRoles()
      },
      {
        title: 'eLearning',
        icon: <IconBook2 />,
        link: AdminRoutes.courseList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        title: 'Events',
        icon: <IconCalendarEvent />,
        link: AdminRoutes.eventList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        title: 'LHD Coordinator View',
        icon: <IconZoomIn />,
        link: Routes.LHD,
        roles: getAllAdminRoles()
      },
      {
        title: 'Coupons',
        icon: <IconTicket />,
        link: AdminRoutes.coupon,
        roles: [UserRoles.IOI_ADMIN]
      }
    ]
  },
  {
    header: 'eLibrary',
    items: [
      {
        title: 'Modules',
        icon: <IconPackage />,
        link: AdminRoutes.scormList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        title: 'Surveys',
        icon: <IconZoomQuestion />,
        link: AdminRoutes.surveyList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        title: 'Media Library',
        icon: <IconPhoto />,
        link: AdminRoutes.fileList,
        roles: [UserRoles.IOI_ADMIN]
      }
    ]
  },
  {
    header: 'Reporting',
    items: [
      {
        title: 'Data export',
        icon: <IconDatabaseExport />,
        link: AdminRoutes.dataExport,
        roles: [UserRoles.IOI_ADMIN, UserRoles.F2F_FACILITATOR]
      },
      {
        title: 'Reports',
        icon: <IconReportAnalytics />,
        link: AdminRoutes.reports,
        roles: [
          UserRoles.IOI_ADMIN,
          UserRoles.LHD_COORDINATOR,
          UserRoles.F2F_FACILITATOR
        ]
      }
    ]
  }
];

const useStyles = createStyles(theme => ({
  navbar: {
    padding: theme.spacing.md,
    backgroundColor: theme.black,
    boxShadow: theme.shadows.md,
    transition: 'all 200ms ease-out'
  },
  logo: {
    alignItems: 'baseline',
    'button': {
      color: theme.colors.gray[4],
      height: '24px',
      '&:hover': {
        color: theme.white
      }
    }
  },
  group: {
    color: theme.white,
    letterSpacing: '0.025em',
    '&:not(:first-of-type)': {
      marginTop: theme.spacing.xl
    }
  },
  header: {
    fontWeight: 'normal',
    marginBottom: rem(6)
  },
  link: {
    padding: rem(6),
    display: 'block',
    color: theme.colors.gray[4],
    transition: 'all 100ms ease-in-out',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.colors.cyan[7],
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      transition: 'all 100ms ease-in-out'
    },
    '&.active': {
      color: theme.colors.cyan[4],
      backgroundColor: 'transparent',
      fontWeight: 'bold'
    },
    '&:not(:first-of-type)': {
      marginTop: rem(2)
    }
  }
}));

export const Sidebar = () => {
  const { pathname } = useLocation();
  const { classes, cx } = useStyles();
  const currentUserQuery = useCurrentUser();

  const [expanded, { toggle: toggleSidebar }] = useDisclosure(true);

  const role = currentUserQuery.data?.data?.role ?? 'LEARNER';

  const groups = useMemo<Array<SidebarGroup>>(() => {
    const filteredGroups = [];

    sidebarDef.forEach(group => {
      const filteredItems = group.items.filter(item =>
        item.roles.includes(role)
      );

      if (filteredItems.length)
        filteredGroups.push({ ...group, items: filteredItems });
    });

    return filteredGroups;
  }, [role]);

  return (
    <Navbar width={{ base: expanded ? 272 : 72 }} className={classes.navbar}>
      <Navbar.Section mb="2rem">
        <Group
          position={expanded ? 'apart' : 'center'}
          className={classes.logo}
        >
          {expanded && (
            <BackgroundImage
              src={logo}
              sx={{ height: '30px', width: '150px' }}
              component={Link}
              to="/"
            />
          )}
          <Tooltip
            label={expanded ? 'Collapse' : 'Expand'}
            position="right"
            withArrow
          >
            <UnstyledButton onClick={() => toggleSidebar()}>
              {expanded ? (
                <IconLayoutSidebarLeftCollapseFilled />
              ) : (
                <IconLayoutSidebarLeftExpandFilled />
              )}
            </UnstyledButton>
          </Tooltip>
        </Group>
      </Navbar.Section>
      <Navbar.Section grow>
        {groups?.map(group => (
          <Navbar.Section key={group.header} className={classes.group}>
            {expanded && (
              <Title order={4} className={classes.header}>
                {group.header}
              </Title>
            )}
            {group.items.map(item => (
              <Anchor
                href={item.link}
                className={cx(classes.link, {
                  active: pathname.startsWith(item.link)
                })}
              >
                {expanded ? (
                  <Group spacing="sm">
                    {item.icon}
                    {item.title}
                  </Group>
                ) : (
                  <Tooltip
                    label={item.title}
                    position="right"
                    offset={24}
                    withArrow
                  >
                    <Group spacing="sm">{item.icon}</Group>
                  </Tooltip>
                )}
              </Anchor>
            ))}
          </Navbar.Section>
        ))}
      </Navbar.Section>
    </Navbar>
  );
};
