import { Box, Tabs } from '@mantine/core';
import { UserRoles } from 'enums';
import { NSWHealthLHD } from 'enums/user-info';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { createUser as createUserRoutine } from 'store/users/routines';
import { RootState } from 'types/state';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { UserList } from 'features/user/components/UserList';
import { LHDCoordinatorList } from 'features/user/components/LHDCoordinatorList';

type Props = {
  isLoading: boolean;
  error?: string;
  fetchCourses: () => void;
  createUser: (arg0: {
    email: string;
    role: keyof typeof UserRoles;
    coordinatorFor?: keyof typeof NSWHealthLHD;
  }) => void;
};

const UsersComponent: React.FC<Props> = ({
  isLoading,
  error,
  fetchCourses,
  createUser
}) => {
  const handleCreateUser = useCallback(
    (arg0: {
      email: string;
      role: keyof typeof UserRoles;
      coordinatorFor?: keyof typeof NSWHealthLHD;
    }) => {
      let type = 'learner';

      if (arg0.role === UserRoles.F2F_FACILITATOR) type = 'facilitator';
      else if (arg0.role === UserRoles.LHD_COORDINATOR) type = 'coordinator';
      else if (arg0.role === UserRoles.IOI_ADMIN) type = 'admin';

      notifications.show({
        id: 'new-user-notification',
        message: `Creating new ${type} account`,
        color: 'green',
        style: { fontWeight: '500' },
        loading: true
      });

      createUser(arg0);
    },
    []
  );

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      notifications.update({
        id: 'new-user-notification',
        message: error
          ? 'An error occured trying to create user account'
          : 'New user account created',
        color: error ? 'red' : 'green',
        icon: error ? <IconX size="1rem" /> : <IconCheck size="1rem" />,
        style: { fontWeight: '500' },
        autoClose: 5000
      });
    }
  }, [isLoading, error]);

  return (
    <Tabs defaultValue="LEARNER" keepMounted={false}>
      <Tabs.List mb="xl">
        <Tabs.Tab value={UserRoles.LEARNER}>Learners</Tabs.Tab>
        <Tabs.Tab value={UserRoles.LHD_COORDINATOR}>LHD Coordinators</Tabs.Tab>
        <Tabs.Tab value={UserRoles.F2F_FACILITATOR}>
          Face to Face Facilitators
        </Tabs.Tab>
        <Tabs.Tab value={UserRoles.IOI_ADMIN}>InsideOut Admins</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value={UserRoles.LEARNER}>
        <Box>
          <UserList userType="LEARNER" />
        </Box>
      </Tabs.Panel>
      <Tabs.Panel value={UserRoles.LHD_COORDINATOR}>
        <Box>
          <LHDCoordinatorList />
        </Box>
      </Tabs.Panel>
      <Tabs.Panel value={UserRoles.F2F_FACILITATOR}>
        <Box>
          <UserList userType="F2F_FACILITATOR" />
        </Box>
      </Tabs.Panel>
      <Tabs.Panel value={UserRoles.IOI_ADMIN}>
        <Box>
          <UserList userType="IOI_ADMIN" />
        </Box>
      </Tabs.Panel>
    </Tabs>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.users.isLoading,
  error: state.users.error
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine,
  createUser: createUserRoutine
};

export const Users = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersComponent);
