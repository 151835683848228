import React from 'react';
import { Header, Container, Group, Text, Button } from '@mantine/core';

type Props = {
  email: string;
  staging?: boolean;
  logout: () => void;
};

export const AdminHeader: React.FC<Props> = ({ email, staging, logout }) => {
  return (
    <Header height={72}>
      <Container
        display="flex"
        size="xl"
        sx={{ height: '100%', alignItems: 'center' }}
      >
        <Text fw="bold" color="gray" size="lg">
          eLearning
        </Text>

        <Group position="right" sx={{ flex: '1 !important' }} noWrap>
          <Text>{email}</Text>
          <Button onClick={() => logout()} color="cyan" uppercase>
            Log Out
          </Button>
        </Group>
      </Container>
    </Header>
  );
};
