import { Badge, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ColumnDef } from '@tanstack/react-table';
import { SortableHeader } from 'components/Table/SortableHeader';
import { DataTableVirtual } from 'components/Table/virtual';
import { UserRoles } from 'enums';
import React, { useState } from 'react';
import { User } from 'types/backend';
import { useUsers } from '../api/get-users';
import { CreateUserModal } from './CreateUserModal';
import { UserDetailsDrawer } from './UserDetailsDrawer';

interface UserListProps {
  userType: keyof typeof UserRoles;
}

const columns: ColumnDef<User>[] = [
  {
    id: 'name',
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          Name
        </SortableHeader>
      );
    },
    accessorFn: row =>
      row.first_name ? `${row.first_name} ${row.last_name}` : '',
    enableSorting: true
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Active',
    cell: ({ row }) => {
      if (row.original.is_active) {
        return <Badge>Active</Badge>;
      }

      return <Badge color="red">Inactive</Badge>;
    }
  }
];

export const UserList = ({ userType }: UserListProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedUserId, setSelectedUserId] = useState('');

  const usersQuery = useUsers({ role: userType });

  if (usersQuery.isLoading) {
    return null;
  }

  const users = usersQuery.data?.data;

  if (!users) return null;

  return (
    <>
      <Stack>
        <CreateUserModal />
        <DataTableVirtual
          id={userType}
          columns={columns}
          data={users}
          rowSize={36}
          filterInputPlaceholder="Filter users..."
          sortable
          filterable
          onRowClick={row => {
            setSelectedUserId(row.id);
            open();
          }}
        />
      </Stack>
      <UserDetailsDrawer
        userId={selectedUserId}
        open={opened}
        onClose={close}
      />
    </>
  );
};
