import { UserRoles } from '../user-roles';

export enum Routes {
  BaseUrl = '/',
  Landing = '/landing',
  Administration = '/administration',
  Dashboard = '/dashboard',
  Login = '/login',
  Registration = '/registration',
  AwaitingActivation = '/awaiting-activation',
  Help = '/help',
  ContactUs = '/contact-us',
  TermsAndConditions = '/terms-and-conditions',
  PrivacyPolicy = '/privacy-policy',
  Profile = '/profile',
  AccountActivation = '/activate-account',
  Trainings = '/trainings',
  Events = '/events',
  LHD = '/LHD-Coordinator',
  Reports = '/reports',
  DirectSurvey = '/direct-survey',
  ExpressionOfInterestViewer = '/view-eoi',
  formsAndFlyers = '/forms-and-flyers',
  approveRequests = '/approve-requests',
  evaluationReports = '/evaluation-reports',
  courseEnrolment = '/course-enrolment',
  CourseStore = '/store',
  ExpressionOfInterest = '/expression-of-interest',
  Certificates = '/certificates',
  AccountSettings = '/account-settings',
  ResetPassword = '/reset-password',
  ResetPasswordRequest = '/reset-password-request',
  CourseResources = '/course-resources',
  F2FResources = '/f2f-resources',
  CalendarPage = '/calendar',
  AttendanceSheet = '/attendance-sheet'
}

export const PublicRoutes: readonly string[] = Object.freeze([
  Routes.Login,
  Routes.AwaitingActivation,
  Routes.Registration,
  Routes.Landing,
  Routes.Help,
  Routes.ContactUs,
  Routes.TermsAndConditions,
  Routes.PrivacyPolicy,
  Routes.AccountActivation,
  Routes.DirectSurvey,
  Routes.CourseStore,
  Routes.ExpressionOfInterest,
  Routes.ResetPassword,
  Routes.ResetPasswordRequest
]);

const roleToBaseUrl = new Map()
  .set(UserRoles.GUEST, Routes.Landing)
  .set(UserRoles.LEARNER, Routes.Dashboard)
  .set(UserRoles.IOI_ADMIN, Routes.Administration)
  .set(UserRoles.F2F_FACILITATOR, Routes.Administration)
  .set(UserRoles.LHD_COORDINATOR, Routes.Administration);

export const getBaseUrlByRole = (role: UserRoles) => {
  const out = roleToBaseUrl.get(role);
  return out || Routes.Landing;
};
