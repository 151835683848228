import { flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import React from 'react';

interface VirtualRowProps<TData> {
  row: Row<TData>;
  virtualRow: VirtualItem;
  virtualIndex: number;
  handleOnClick?: (row: TData) => void;
}

export const VirtualRow = <TData,>({
  row,
  virtualRow,
  virtualIndex,
  handleOnClick
}: VirtualRowProps<TData>) => {
  return (
    <tr
      key={row.id}
      style={{
        height: `${virtualRow.size}px`,
        transform: `translateY(${
          virtualRow.start - virtualIndex * virtualRow.size
        }px)`
      }}
      onClick={() => handleOnClick && handleOnClick(row.original)}
    >
      {row.getVisibleCells().map(cell => (
        <td key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};
