export const EventEnrolmentStatusColors = Object.freeze({
  PENDING: 'yellow',
  APPROVED: 'green',
  REJECTED: 'red',
  COMPLETE: 'blue',
  WITHDRAWN: 'dark'
});

export enum EventEnrolmentStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  COMPLETE = 'Complete',
  WITHDRAWN = 'Withdrawn'
}
