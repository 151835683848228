import { Alert, Button, Modal, Select, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertCircle, IconPlus } from '@tabler/icons-react';
import React from 'react';

import {
  CreateUserBody,
  CreateUserBodySchema,
  useCreateUser
} from '../api/create-user';

const UserRoleData = [
  {
    value: 'LEARNER',
    label: 'Learner'
  },
  {
    value: 'F2F_FACILITATOR',
    label: 'F2F Facilitator'
  },
  {
    value: 'LHD_COORDINATOR',
    label: 'LHD Coordinator'
  },
  {
    value: 'IOI_ADMIN',
    label: 'IOI Admin'
  }
];
export const CreateUserModal = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm<CreateUserBody>({
    initialValues: {
      email: '',
      role: 'LEARNER'
    },
    validate: zodResolver(CreateUserBodySchema)
  });

  const createUserMutation = useCreateUser({
    mutationConfig: {
      onSuccess: () => {
        close();
      }
    }
  });

  return (
    <>
      <Modal opened={opened} onClose={close} title="Create New User" centered>
        <form
          onSubmit={form.onSubmit(values => {
            createUserMutation.mutate({
              data: values
            });
          })}
        >
          <Stack>
            {createUserMutation.isError && (
              <Alert
                icon={<IconAlertCircle size="1rem" />}
                color="red"
                title="An error occured!"
              >
                Failed to create user
              </Alert>
            )}
            <TextInput
              label="Email"
              placeholder="example@email.com"
              {...form.getInputProps('email')}
            />
            <Select
              data={UserRoleData}
              label="Select a role"
              placeholder="Pick one"
              withinPortal
              {...form.getInputProps('role')}
            />
            <Button type="submit" color="dark">
              Create User
            </Button>
          </Stack>
        </form>
      </Modal>
      <Button color="dark" leftIcon={<IconPlus />} onClick={open}>
        Create User
      </Button>
    </>
  );
};
